<template>
  <div class="home space-y-3">
    <Story  v-if="isWeekend"/>
    <Banner />
    <Article />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { applyMeta } from '@/utils/meta'
import { gunsaImpression } from '@/utils/trackers'

const Story = defineAsyncComponent(() => import('@/components/home/Story.vue'))
const Banner = defineAsyncComponent(() => import('@/components/home/Banner.vue'))
const Article = defineAsyncComponent(() => import('@/components/home/Article.vue'))
export default {
  name: 'Home',
  components: {
    Banner,
    Article,
    Story
  },
  created () {
    applyMeta({
      title: 'GKI Gunsa PWA Apps',
      metaData: [
        {
          qualifiedName: 'name',
          qualifiedNameValue: 'description',
          value: 'GKI Gunsa PWA Apps'
        },
        {
          qualifiedName: 'name',
          qualifiedNameValue: 'keywords',
          value: 'GKI Gunsa PWA Apps'
        }
      ]
    })
    gunsaImpression({ page: 'Homepage', title: 'Homepage', value: 'www.gkigunsa.com' })
  },
  computed: {
    isWeekend () {
      const date = new Date()
      return date.getDay() === 0 || date.getDay() === 6
    }
  }
}
</script>
