const homeConfig = {
  articleDefaultQueryParams: {
    page: 1,
    itemPerPage: 10,
    search: ''
  },
  articleType: {
    youtube: 'YOUTUBE',
    pdf: 'PDF'
  }
}
const scheduleConfig = {
  type: {
    public: 'PUBLIC',
    teen: 'TEEN',
    kids: 'KIDS'
  }
}
const commonConfig = {
  months: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sept', 'Okt', 'Nov', 'Des'],
  mainMenu: ['Home', 'Fellowship', 'Schedule'],
  appVersion: '2.4.0'
}

const worshipConfig = {
  worships: [
    {
      sequence: 1,
      time: '06.00'
    },
    {
      sequence: 2,
      time: '08.00'
    },
    {
      sequence: 3,
      time: '10.30'
    },
    {
      sequence: 4,
      time: '17.00'
    }
  ]
}

export {
  homeConfig,
  commonConfig,
  scheduleConfig,
  worshipConfig
}
